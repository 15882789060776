import JSZip from "jszip";
import FileSaver from "file-saver";
// 手机号校验
export function phoneFun(phones) {
  var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
  //  var myreg = /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/;
  if (!myreg.test(phones)) {
    console.log("手机号格式不正确");
    return false;
  } else {
    console.log("手机号格式正确");
    return true;
  }
}
export function isCardNo(card) {
  // 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X
  var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
  if (reg.test(card) === false) {
    return false;
  } else {
    return true;
  }
}
// 点击下载
export function downloadZIP(resources = [], blogTitle = "下载文件名字") {
  // var blogTitle = name; // 下载后压缩包的命名

  return new Promise((resolve, reject) => {
    try {
      var zip = new JSZip();
      var promises = [];
      let cache = {};
      let arrImg = [];
      for (let i = 0; i < resources.length; i++) {
        arrImg.push({
          path: resources[i].url, // 文件链接
          name: resources[i].name, // 文件名称
        });
      }
      for (let item of arrImg) {
        // item.path为文件链接地址
        const promise = getImgArrayBuffer(item.path).then((data) => {
          // 下载文件, 并存成ArrayBuffer对象(blob)
          zip.file(item.name, data, { binary: true }); // 逐个添加文件
          cache[item.name] = data;
        });
        promises.push(promise);
      }
      Promise.all(promises)
        .then(() => {
          zip.generateAsync({ type: "blob" }).then((content) => {
            // 生成二进制流
            FileSaver.saveAs(content, blogTitle); // 利用file-saver保存文件  自定义文件名
            resolve(true);
          });
        })
        .catch((res) => {
          reject("压缩失败");
        });
    } catch (error) {
      reject("操作错误");
    }
  });
}

//文件以流的形式获取（参数url为文件链接地址）
export function getImgArrayBuffer(url) {
  return new Promise((resolve, reject) => {
    //通过请求获取文件blob格式
    let xmlhttp = new XMLHttpRequest();
    xmlhttp.open("GET", url, true);
    xmlhttp.responseType = "blob";
    xmlhttp.onload = function () {
      if (this.status == 200) {
        resolve(this.response);
      } else {
        reject(this.status);
      }
    };
    xmlhttp.send();
  });
}

//文件通过链接link下载
export function downloadFileLink(url, fileName) {
  var a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  a.click();
  document.body.removeChild(a);
}

// 金额数字校验 e: 值  time: 修改的对象  key： 修改对象中的值   max： 最大值
export function amountInputNum(e, tiem, key, max) {
  // 清除"数字"和"."以外的字符
  tiem[key] = e.replace(/[^\d.]/g, "") || 0;
  // 验证第一个字符是数字
  tiem[key] = e.replace(/^\./g, "") || 0;
  // 只保留第一个, 清除多余的
  tiem[key] = e.replace(/\.{2,}/g, ".") || 0;
  tiem[key] = e.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".") || 0;
  // 只能输入两个小数
  tiem[key] = e.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3") || 0;

  //如果有小数点，不能为类似 1.10的金额
  if (e.indexOf(".") > 0 && e.indexOf("0") > 2) {
    tiem[key] = parseFloat(e);
  }
  //如果有小数点，不能为类似 0.00的金额
  if (e.indexOf(".") > 0 && e.lastIndexOf("0") > 2) {
    tiem[key] = parseFloat(e);
  }
  //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
  if (e.indexOf(".") <= 0 && e != "") {
    tiem[key] = parseFloat(e) || 0;
  }
  if (e > max) {
    const aa = e + "";
    tiem[key] = parseFloat(aa.slice(0, e.length - 1));
  }
}

// 邮箱校验
export function judgeEmail(email) {
  var emailReg = /^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/;
  if (emailReg.test(email)) {
    return true;
  } else {
    return false;
  }
}

// 密码类型校验
export function judgePassword(v) {
  var passwordT = /^\w{6,12}$/;
  if (passwordT.test(v)) {
    return true;
  } else {
    return false;
  }
}

// let fileSelect = null;                           // 文件选择框
// const FILE_TYPE = ['xls', 'xlsx'];       // 限制文件上传类型
// const FILE_MAX_SIZE = 10 * 1024 * 1024;      // 限制文件大小
// const ACCEPT = ".xls,.xlsx";        // 限制选择文件的类型
// 选择文件功能
/**
 *
 * @param {*} accept   限制选择文件类型，默认.xls,.xlsx
 * @param {*} fileTypes    校验上传前文件类型, 默认['xls','xlsx']  , 单个类型可传字符串
 * @param {*} fileSize      限制文件上传大小
 */

export function uploadFile({ accept, fileTypes, fileSize }) {
  return new Promise((resolve, reject) => {
    let fileSelect = null;
    // 创建一个选择文件的input框
    if (!fileSelect) {
      fileSelect = document.createElement("input");
      fileSelect.type = "file";
      fileSelect.style.display = "none";
      document.body.append(fileSelect);
    }
    // 获取文件类型，大小限制
    let mineType = accept || ".png,.jpg";
    let fileMaxSize = fileSize ? fileSize * 1024 * 1024 : 2 * 1024 * 1024;
    let fileType = ["png", "jpg"];
    if (fileTypes) {
      //  当只有一种文件类型时传入的可能是一个字符串
      if (!Array.isArray(fileTypes)) {
        fileTypes = [fileTypes];
      }
      fileType = fileTypes;
    }
    // 限制可以选择的文件类型
    fileSelect.accept = mineType;
    // 监听input的change事件获取选择的文件
    fileSelect.onchange = function (e) {
      // 获取文件
      let file = e.target.files[0];
      // 判断文件类型
      let currentFileType = file.name.slice(file.name.lastIndexOf(".") + 1);
      // 如果当前选择的文件不在限制的文件类型范围内，提示选择错误文件
      if (fileTypes && !fileType.includes(currentFileType)) {
        // 这里可以统一处理错误提示信息，也可以按情况分开处理即调用方法的时候再处理
        // Message({
        //     message: message || '只允许上传excel类型的文件！',
        //     type: 'info',
        //     duration: 5 * 1000
        // })
        // 将input选择器里面的内容置空，并将结果返回出去
        fileSelect.value = "";
        reject("文件类型错误");
        return;
      }
      // 判断文件大小
      if (file.size > fileMaxSize) {
        //  这里的处理方式一样，可以统一处理，也可以先不管
        // Message({
        //     message: `单个文件最大不超过${fileSize?fileSize:10}MB！`,
        //     type: 'info',
        //     duration: 5 * 1000
        // })
        // 将input选择器里面的内容置空，并将结果返回出去
        fileSelect.value = "";
        reject("文件大小超出限制");
        return;
      }
      // 将input选择器里面的内容置空，并将选择到的文件结果传出去
      fileSelect.value = "";
      resolve(file);
    };
    // 自动触发点击事件，选择文件
    fileSelect.click();
  });
}

// 文件类型转base64
export function fileToBase64(file) {
  //file转bse64
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      resolve(e.target.result);
    };
  });
}

// 金额三位间隔
export function interval(key) {
  key += "";
  let x = key.split(".");
  let x1 = x[0];
  let x2 = x.length > 1 ? "." + x[1] : "";
  let reg = /(\d+)(\d{3})/;
  while (reg.test(x1)) {
    x1 = x1.replace(reg, "$1" + "," + "$2");
    // x2 = x2.replace(reg, '$1' + ',' + '$2');
  }
  return x1 + x2;
}

// 计算百分比
export function GetPercent(num, total, type) {
  /// <param name="num">当前数</param>
  /// <param name="total">总数</param>
  num = parseFloat(num);
  total = parseFloat(total);
  if (isNaN(num) || isNaN(total)) {
    return "-";
  }
  return total <= 0
    ? 0
    : type
    ? Math.floor((num / total) * 10000) / 100.0
    : Math.round((num / total) * 10000) / 100.0;
}
